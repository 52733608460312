@import '~/styles/styles';

.prod-tangibles {
  padding: 30px;
  text-align: center;
  display: grid;
  gap: 30px;
  justify-items: center;

  .tangible-items {
    display: grid;
    gap: 80px 100px;
    grid-template-columns: repeat(2, 400px);

    @include tablet {
      grid-template-columns: repeat(2, 300px);
      gap: 50px;
    }

    @include mobile {
      grid-template-columns: repeat(2, 160px);
      gap: 10px;
    }

    h3 {
      margin-bottom: 6px;
    }

    .tangible-item {
      display: grid;
      align-content: start;
      justify-items: center;

      .wp-control {
        white-space: normal;
        &::after {
          white-space: normal;
        }
      }

      .media-container {
        place-self: center;
        width: 100%;
        margin-bottom: 14px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
  
}