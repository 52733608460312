@import '~/styles/styles';

.packtype-container {
  & > hr {
    width: calc(100vw - 80px);
    max-width: 1360px;
    margin: 20px auto;
  }
}

.pack-purchase-detail-container {
  display: grid;
  grid-template-columns: 30% auto;
  padding-top: 30px;
  padding-bottom: 15px;
  grid-column-gap: 30px;

  @include tablet {
    grid-template-columns: none;
  }

  .img-container {
    max-width: 400px;
    position: relative;

    &.series-one {
      span {
        &:first-of-type {
          position: absolute !important;
          // display: none !important;
          z-index: 10;
          width: 200px !important;
          bottom: -30px;
          right: -25px;
          aspect-ratio: 1.8;
          animation: zoom 250ms 800ms linear forwards;
          transform: perspective(200px) translateZ(200px);
        }
      }
    }

    @keyframes zoom {
      to {
        transform: perspective(200px) translateZ(0);
      }
    }

    span {
      width: 100% !important;
      aspect-ratio: 1;

      img {
        height: 300px;
        width: 300px;
      }
    }
  }

  .copy-container {
    display: grid;

    @include tablet {
      margin: 20px 0 0;
    }

    .header {
      display: flex;
      justify-content: space-between;
    }

    hr {
      width: 100%;
      margin: 15px 0;
    }

    .pack-info {
      h4 {
        margin-bottom: 20px;
      }

      ul {
        margin-bottom: 20px;
      }

      @include mobile {
        order: 1;
      }
    }

    button {
      // TODO: Dre must find a better way to not target mailchimp
      // margin-top: 20px;
      justify-self: start;

      @include mobile {
        width: 100%;
        order: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.packodds-container {
  padding: 80px 0;

  @include mobile {
    padding: 15px 0 30px;
  }

  .header {
    h2,
    h3 {
      text-align: center;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
    margin: 0 auto;

    @include mobile {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
  }

  .nft-item {
    display: grid;
    place-items: center;
    text-align: center;

    .img-container {
      width: 300px;
      height: 300px;

      @include mobile {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.faq-container {
  display: grid;
  grid-row-gap: 40px;
  padding: 60px 140px;
  text-align: center;

  @include mobile {
    padding: 40px;
  }
}

.email-button {
  width: 40%;

  @include mobile {
    width: 100%;
  }
}

.purchase-limit {
  @include mobile {
    text-align: center;
    margin: 10px 0;
  }
}

.sale-ends-message {
  margin-top: 20px;
  max-width: 40%;

  @include mobile {
    max-width: 100%;
  }
}
