@import '~/styles/styles';

.header-main {
  display: grid;
  place-content: center;
  padding: 50px 0;

  @include mobile {
    padding: 0;
  }
}

.header-container {
  display: flex;
  grid-gap: 60px;

  @include mobile {
    margin-bottom: 50px;
  }

  section {
    width: 720px;
    aspect-ratio: 16/9;
  }
}

.copy {
  display: flex;
  flex-direction: column;
  place-content: center;
  color: $white;
  max-width: 480px;

  @include mobile {
    width: 90%;
  }

  button {
    align-self: center;

    @include mobile {
      margin: 30px 0 0;
      width: 100%;
    }
  }

  h1 {
    text-align: center;
  }
}

@include custom-breakpoint(1200) {
  .header-container {
    flex-direction: column-reverse;
    grid-gap: 20px;
    align-items: center;
    padding-top: 30px;

    section {
      width: 75vw;
      height: unset;
      order: 2;

      @include mobile {
        width: 100%;
      }
    }

    .copy {
      order: 1;

      @include mobile {
        .container {
          padding: 0;

          input {
            margin-bottom: 0;
          }
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.videos-container {
  display: flex;
  padding: 0 10px;
  max-width: 100vw;
  gap: 20px;
  @include custom-breakpoint(1200) {
    flex-direction: column-reverse;
  }

  div {
    margin: 0;
  }

  iframe {
    margin: 0;
  }
}

.youtube-embed {
  height: 100%;
  width: 100%;
}

.twitch-embed {
  height: 100%;
  width: 100%;
}

.header-series-title {
  display: grid;
  grid-auto-flow: column;

  @include custom-breakpoint(1200) {
    grid-auto-flow: row;
  }
}

.background-gradient {
  background: linear-gradient(90deg, #b321de 0%, #9260ff 0.01%, #4544ff 101.49%);
  height: 190px;
  @include mobile {
    height: 190px;
  }
}

.width {
  width: 75%;
  margin: 0 auto;
}

.margin-auto {
  margin: 0 auto;
}

button.button-black {
  background: black !important;
}

.margin-20 {
  margin: 20px 0;
}

.margin-top-20 {
  margin-top: 20px;
}

.glitch-background {
  @include background-img($backgroundBigGlitch);
}